import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ExtraOptions, PreloadAllModules, RouterModule} from '@angular/router';
import {FuseModule} from '@fuse';
import {FuseConfigModule} from '@fuse/services/config';
import {FuseMockApiModule} from '@fuse/lib/mock-api';
import {CoreModule} from 'app/core/core.module';
import {appConfig} from 'app/core/config/app.config';
import {mockApiServices} from 'app/mock-api';
import {LayoutModule} from 'app/layout/layout.module';
import {AppComponent} from 'app/app.component';
import {appRoutes} from 'app/app.routing';
import {EditorModule} from '@tinymce/tinymce-angular';
import {DirectivesModule} from '@portal/shared/directives/directives.modules';
import {MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS} from '@angular-material-components/color-picker';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {FuseAlertModule} from '@portal/fuse/components/alert';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedMatTableModule} from '@portal/shared/table-module/shared-mat-table.module';
import {MAT_CHIPS_DEFAULT_OPTIONS} from "@angular/material/chips";
import {COMMA, SPACE} from "@angular/cdk/keycodes";
import { ClientsComponent } from './modules/organization/clients/clients.component';
import {TranslocoModule} from "@ngneat/transloco";

registerLocaleData(localePt, 'pt-PT');

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent,
        ClientsComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        EditorModule,
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        DirectivesModule,
        FuseAlertModule,
        ReactiveFormsModule,
        SharedMatTableModule,
        TranslocoModule,

    ],
    exports: [],
    bootstrap: [
        AppComponent
    ],
    providers: [
        { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
        { provide: LOCALE_ID, useValue: 'pt-PT' },
        {
            provide: MAT_CHIPS_DEFAULT_OPTIONS,
            useValue: {
                separatorKeyCodes: [COMMA, SPACE]
            }
        }
    ]
})
export class AppModule {
}
