<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">

    <!-- Header -->
    <div
        class="relative flex flex-col flex-0 justify-center w-full overflow-hidden z-49 shadow dark:shadow-none print:hidden">
        <!-- Top bar -->
        <div class="relative dark flex flex-auto w-full px-4 md:px-8 bg-gray-800 dark:bg-gray-900">
            <div class="flex items-center w-full">
                <!-- Logo -->
                <div class="flex items-center text-2xl font-bold cursor-pointer" routerLink="/">
<!--                    Insurance Care 360-->
                    SWINANCE
                </div>
                <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
                    <!-- Bottom bar -->
                    <div class="flex flex-auto justify-center px-4 md:px-8 bg-card dark:bg-gray-700">
                        <div class="relative flex items-center w-full">
                            <fuse-horizontal-navigation
                                class="-mx-4"
                                [name]="'mainNavigation'"
                                [navigation]="navigation"></fuse-horizontal-navigation>
                        </div>
                    </div>
                    <languages></languages>
                    <user></user>
                </div>
            </div>
        </div>
        <div class="relative dark flex flex-auto w-full px-4 md:px-8 bg-gray-800 dark:bg-gray-900"
             *ngIf="this.isAuthenticated">
<!--            <button class="menu-btn" mat-button>{{ 'DASHBOARD' | transloco }}</button>-->
            <button class="menu-btn" mat-button [routerLink]="'/clients'"
                    routerLinkActive="active">{{ 'CLIENTS' | transloco }}
            </button>
        </div>

    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full">
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 justify-center w-full px-6 md:px-8 z-49 border-t bg-card print:hidden footer">
        <div class="flex items-center w-full max-w-360 h-14 sm:h-20 footer">
            <span class="font-medium text-secondary">Insurance Care 360 &copy; {{ currentYear }}</span>
            <span class="ml-auto cursor-pointer" routerLink="privacy">{{ 'PRIVACY_POLICY' | transloco }}</span>
            <span class="ml-4 cursor-pointer" routerLink="terms-of-service">{{ 'TERMS_OF_USE' | transloco }}</span>
        </div>
    </div>

</div>

