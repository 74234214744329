import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {StorageService} from "@portal/shared/services/storage.service";
import {AuthService} from "../../../../core/auth/auth.service";

@Component({
    selector: 'enterprise-layout',
    templateUrl: './enterprise.component.html',
    encapsulation: ViewEncapsulation.None
})
export class EnterpriseLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: FuseNavigationItem[] = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _storageService: StorageService,
        private _authService: AuthService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    get isAuthenticated(): boolean {
        return this._authService.isAuthenticated;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // let menuItem: FuseNavigationItem = {
        //     title: 'Supported List',
        //     type: "basic",
        //     link: "/supported-list",
        // };
        // this.navigation.push(menuItem);

        this
            ._fuseMediaWatcherService
            .onMediaChange$
            .pipe(takeUntil

            (
                this
                    ._unsubscribeAll
            ))
            .subscribe(
                ({
                     matchingAliases
                 }
                ) => {

                    // Check if the screen is small
                    this.isScreenSmall = !matchingAliases.includes('md');
                }
            )
        ;
    }

    /**
     * On destroy
     */
    ngOnDestroy()
        :
        void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

// -----------------------------------------------------------------------------------------------------
// @ Public methods
// -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name
                         :
                         string
    ):
        void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
